export function setQueryParams(obj, history) {
  const params = new URLSearchParams();

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (typeof value === 'number' && key !== 'is_active') {
      params.set(key, value); // stores number
    } else {
      params.set(key, String(value));
    }
  });

  // Update the URL with the new query string
  history.push({
    pathname: history.location.pathname,
    search: params.toString(),
  });
}

export function getQueryParams(location) {
  const params = new URLSearchParams(location.search);
  const obj = {};

  params.forEach((value, key) => {
    if (!isNaN(value) && key !== 'is_active') {
      // Convert numbers
      obj[key] = Number(value);
    } else {
      // Otherwise, treat as string
      obj[key] = value;
    }
  });

  return obj;
}
